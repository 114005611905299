import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card card-p" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-7" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-md-12" }
const _hoisted_6 = { class: "col-md-6" }
const _hoisted_7 = { class: "col-md-6" }
const _hoisted_8 = { class: "col-md-6" }
const _hoisted_9 = { class: "col-md-6" }
const _hoisted_10 = { class: "col-md-6" }
const _hoisted_11 = { class: "col-md-6" }
const _hoisted_12 = {
  key: 0,
  class: "row"
}
const _hoisted_13 = { class: "grid-content bg-purple" }
const _hoisted_14 = {
  class: "d-flex flex-stack fs-6 fw-bold mb-1",
  "data-v-9d4a844a": ""
}
const _hoisted_15 = {
  class: "symbol symbol-50px me-6",
  "data-v-9d4a844a": ""
}
const _hoisted_16 = {
  class: "d-flex flex-column",
  "data-v-9d4a844a": ""
}
const _hoisted_17 = {
  class: "fs-6",
  "data-v-9d4a844a": ""
}
const _hoisted_18 = {
  class: "fs-7 text-muted",
  "data-v-9d4a844a": ""
}
const _hoisted_19 = { class: "customer-permission" }
const _hoisted_20 = { class: "col-md-12 mt-5 relation-transfer" }
const _hoisted_21 = { class: "transfer-data" }
const _hoisted_22 = { class: "col-md-1 text-center" }
const _hoisted_23 = { class: "col-md-4" }
const _hoisted_24 = { class: "section-border pt-7 pb-4" }
const _hoisted_25 = { class: "d-flex align-items-center fs-5 fw-bold mb-2" }
const _hoisted_26 = { class: "section-border pt-7 pb-2" }
const _hoisted_27 = { class: "d-flex align-items-center fs-5 fw-bold mb-2 required" }
const _hoisted_28 = { class: "section-border pt-7 pb-2" }
const _hoisted_29 = { class: "d-flex align-items-center fs-5 fw-bold mb-2 required" }
const _hoisted_30 = { class: "section-border pt-7 pb-2" }
const _hoisted_31 = { class: "d-flex align-items-center fs-5 fw-bold mb-2" }
const _hoisted_32 = {
  class: "mt-7",
  style: {"text-align":"end"}
}
const _hoisted_33 = { class: "indicator-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InnerLoader = _resolveComponent("InnerLoader")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Select = _resolveComponent("Select")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_Permissions = _resolveComponent("Permissions")!
  const _component_el_transfer = _resolveComponent("el-transfer")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_Radio = _resolveComponent("Radio")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_InnerLoader, { loading: _ctx.loader }, null, 8, ["loading"]),
    _createVNode(_component_el_form, {
      model: _ctx.customer,
      ref: "addCustomerForm",
      loading: _ctx.loading
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, [
            _createVNode(_component_el_tabs, {
              modelValue: _ctx.tabs,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.tabs = $event)),
              class: "demo-tabs"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_tab_pane, {
                  label: _ctx.$t('message.CUSTOMER')
                }, {
                  default: _withCtx(() => [
                    _createVNode("div", _hoisted_4, [
                      _createVNode("div", _hoisted_5, [
                        _createVNode(_component_Input, {
                          type: "text",
                          name: "name",
                          label: _ctx.$t('message.NAME'),
                          placeholder: `${_ctx.$t('message.ENTER')} ${_ctx.$t('message.NAME')}`,
                          requiredMsg: `${_ctx.$t('message.NAME')} ${_ctx.$t('message.IS_REQUIRED')}`,
                          required: true,
                          "onUpdate:name": _cache[1] || (_cache[1] = ($event: any) => (_ctx.customer.name = $event)),
                          target: "name",
                          value: _ctx.customer.name
                        }, null, 8, ["label", "placeholder", "requiredMsg", "name", "value"])
                      ]),
                      _createVNode("div", _hoisted_6, [
                        _createVNode(_component_Input, {
                          type: "text",
                          name: "email",
                          label: _ctx.$t('message.EMAIL'),
                          required: true,
                          placeholder: `${_ctx.$t('message.ENTER')} ${_ctx.$t('message.EMAIL')}`,
                          requiredMsg: `${_ctx.$t('message.EMAIL')} ${_ctx.$t('message.IS_REQUIRED')}`,
                          email: _ctx.customer.email,
                          "onUpdate:email": _cache[2] || (_cache[2] = ($event: any) => (_ctx.customer.email = $event)),
                          target: "email",
                          rule: "email",
                          ruleMsg: _ctx.$t('message.ENTER_A_VALID_EMAIL_ADDRESS'),
                          value: _ctx.customer.email
                        }, null, 8, ["label", "placeholder", "requiredMsg", "email", "ruleMsg", "value"])
                      ]),
                      _createVNode("div", _hoisted_7, [
                        _createVNode(_component_Input, {
                          type: "password",
                          name: "password",
                          label: _ctx.$t('message.PASSWORD'),
                          placeholder: `${_ctx.$t('message.ENTER')} ${_ctx.$t('message.PASSWORD')}`,
                          requiredMsg: `${_ctx.$t('message.PASSWORD')} ${_ctx.$t('message.IS_REQUIRED')}`,
                          password: _ctx.customer.password,
                          "onUpdate:password": _cache[3] || (_cache[3] = ($event: any) => (_ctx.customer.password = $event)),
                          target: "password",
                          required: true,
                          value: _ctx.customer.password
                        }, null, 8, ["label", "placeholder", "requiredMsg", "password", "value"])
                      ]),
                      _createVNode("div", _hoisted_8, [
                        _createVNode(_component_Input, {
                          type: "password",
                          name: "password_confirmation",
                          label: `${_ctx.$t('message.CONFIRM')} ${_ctx.$t('message.PASSWORD')}`,
                          placeholder: `${_ctx.$t('message.CONFIRM')} ${_ctx.$t('message.PASSWORD')}`,
                          requiredMsg: `${_ctx.$t('message.CONFIRM')} ${_ctx.$t('message.PASSWORD')} ${_ctx.$t('message.IS_REQUIRED')}`,
                          password_confirmation: _ctx.customer.password_confirmation,
                          "onUpdate:password_confirmation": _cache[4] || (_cache[4] = ($event: any) => (_ctx.customer.password_confirmation = $event)),
                          target: "password_confirmation",
                          required: true,
                          value: _ctx.customer.password_confirmation
                        }, null, 8, ["label", "placeholder", "requiredMsg", "password_confirmation", "value"])
                      ]),
                      _createVNode("div", _hoisted_9, [
                        _createVNode(_component_Input, {
                          type: "text",
                          name: "phone",
                          label: `${_ctx.$t('message.PHONE_NUMBER')}`,
                          placeholder: `${_ctx.$t('message.ENTER')} ${_ctx.$t('message.PHONE_NUMBER')}`,
                          requiredMsg: `${_ctx.$t('message.PHONE_NUMBER')} ${_ctx.$t('message.IS_REQUIRED')}`,
                          phone: _ctx.customer.phone,
                          "onUpdate:phone": _cache[5] || (_cache[5] = ($event: any) => (_ctx.customer.phone = $event)),
                          target: "phone",
                          value: _ctx.customer.phone
                        }, null, 8, ["label", "placeholder", "requiredMsg", "phone", "value"])
                      ]),
                      _createVNode("div", _hoisted_10, [
                        _createVNode(_component_Input, {
                          type: "text",
                          name: "economic_number",
                          label: `${_ctx.$t('message.ECONOMIC')} ${_ctx.$t('message.NUMBER')}`,
                          required: _ctx.economic_number_required,
                          placeholder: `${_ctx.$t('message.ENTER')} ${_ctx.$t('message.ECONOMIC')} ${_ctx.$t('message.NUMBER')}`,
                          requiredMsg: `${_ctx.$t('message.ECONOMIC')} ${_ctx.$t('message.NUMBER')} ${_ctx.$t('message.IS_REQUIRED')}`,
                          economic_number: _ctx.customer.economic_number,
                          "onUpdate:economic_number": _cache[6] || (_cache[6] = ($event: any) => (_ctx.customer.economic_number = $event)),
                          target: "economic_number",
                          value: _ctx.customer.economic_number,
                          onBlur: _ctx.checkEconomicNumber
                        }, null, 8, ["label", "required", "placeholder", "requiredMsg", "economic_number", "value", "onBlur"])
                      ]),
                      _createVNode("div", _hoisted_11, [
                        _createVNode(_component_Select, {
                          name: "country_id",
                          target: "country_id",
                          label: _ctx.$t('message.COUNTRIES'),
                          required: true,
                          filterable: true,
                          placeholder: _ctx.$t('message.SELECT'),
                          country_id: _ctx.customer.country_id,
                          "onUpdate:country_id": _cache[7] || (_cache[7] = ($event: any) => (_ctx.customer.country_id = $event)),
                          selectData: _ctx.countries,
                          value: _ctx.customer.country_id,
                          callEmit: true,
                          onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.changeConfig(_ctx.customer.country_id)))
                        }, null, 8, ["label", "placeholder", "country_id", "selectData", "value"])
                      ])
                    ]),
                    (_ctx.showCountryInfo)
                      ? (_openBlock(), _createBlock("div", _hoisted_12, [
                          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.showCountryInfo[0], (info, index) => {
                            return (_openBlock(), _createBlock("div", {
                              class: "col-md-6",
                              span: 12,
                              key: index
                            }, [
                              _createVNode("div", _hoisted_13, [
                                _createVNode("label", _hoisted_14, [
                                  _createVNode("span", _hoisted_15, [
                                    _createVNode("span", _hoisted_16, [
                                      _createVNode("span", _hoisted_17, _toDisplayString(index), 1),
                                      _createVNode("span", _hoisted_18, _toDisplayString(info), 1)
                                    ])
                                  ])
                                ])
                              ])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_tab_pane, {
                  label: _ctx.$t('message.PERMISSIONS')
                }, {
                  default: _withCtx(() => [
                    _createVNode("div", _hoisted_19, [
                      _createVNode(_component_Permissions, { onPermissions: _ctx.getPermissions }, null, 8, ["onPermissions"])
                    ])
                  ]),
                  _: 1
                }, 8, ["label"]),
                (_ctx.customer.cup_agreement == 1)
                  ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                      key: 0,
                      label: `${_ctx.$t('message.CUP_AGREEMENT')} ${_ctx.$t('message.PRODUCT')}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode("div", _hoisted_20, [
                          _createVNode("div", _hoisted_21, [
                            _createVNode(_component_el_transfer, {
                              modelValue: _ctx.cup_agreement_products,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.cup_agreement_products = $event)),
                              props: {
                                            key: 'value',
                                            label: 'desc'
                                        },
                              data: _ctx.products,
                              filterable: "",
                              titles: [_ctx.$t('message.PRODUCTS'), _ctx.$t('message.AGREEMENT_PRODUCTS')]
                            }, null, 8, ["modelValue", "data", "titles"])
                          ])
                        ])
                      ]),
                      _: 1
                    }, 8, ["label"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _createVNode("div", _hoisted_22, [
            _createVNode(_component_el_divider, { direction: "vertical" })
          ]),
          _createVNode("div", _hoisted_23, [
            _createVNode("div", _hoisted_24, [
              _createVNode("label", _hoisted_25, _toDisplayString(_ctx.$t('message.CUP_AGREEMENT')), 1),
              _createVNode("div", null, [
                _createVNode(_component_Radio, {
                  name: "cup_agreement",
                  size: "medium",
                  target: "cup_agreement",
                  value: _ctx.customer.cup_agreement,
                  cup_agreement: _ctx.customer.cup_agreement,
                  "onUpdate:cup_agreement": _cache[11] || (_cache[11] = ($event: any) => (_ctx.customer.cup_agreement = $event)),
                  options: _ctx.cupAgreements,
                  callEmit: true,
                  onChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.onChangeCupAgreement(_ctx.customer.cup_agreement)))
                }, null, 8, ["value", "cup_agreement", "options"])
              ])
            ]),
            _createVNode("div", _hoisted_26, [
              _createVNode("label", _hoisted_27, _toDisplayString(_ctx.$t('message.SITES')), 1),
              _createVNode(_component_Select, {
                name: "site_id",
                target: "site_id",
                required: true,
                label: false,
                filterable: true,
                placeholder: _ctx.$t('message.SELECT'),
                site_id: _ctx.customer.site_id,
                "onUpdate:site_id": _cache[13] || (_cache[13] = ($event: any) => (_ctx.customer.site_id = $event)),
                selectData: _ctx.sites,
                value: _ctx.customer.site_id
              }, null, 8, ["placeholder", "site_id", "selectData", "value"])
            ]),
            _createVNode("div", _hoisted_28, [
              _createVNode("label", _hoisted_29, _toDisplayString(_ctx.$t('message.CUSTOMER_GROUPS')), 1),
              _createVNode(_component_Select, {
                name: "customer_group",
                target: "customer_group",
                required: true,
                label: false,
                filterable: true,
                callEmit: true,
                onChange: _cache[14] || (_cache[14] = ($event: any) => (_ctx.checkCustomerGroup())),
                placeholder: _ctx.$t('message.SELECT'),
                customer_group: _ctx.customer.customer_group,
                "onUpdate:customer_group": _cache[15] || (_cache[15] = ($event: any) => (_ctx.customer.customer_group = $event)),
                selectData: _ctx.customerGroups,
                value: _ctx.customer.customer_group
              }, null, 8, ["placeholder", "customer_group", "selectData", "value"])
            ]),
            _createVNode("div", _hoisted_30, [
              _createVNode("label", _hoisted_31, _toDisplayString(_ctx.$t('message.PRICE_GROUPS')), 1),
              _createVNode(_component_Select, {
                name: "price_group",
                target: "price_group",
                label: false,
                filterable: true,
                placeholder: _ctx.$t('message.SELECT'),
                price_group: _ctx.customer.price_group,
                "onUpdate:price_group": _cache[16] || (_cache[16] = ($event: any) => (_ctx.customer.price_group = $event)),
                selectData: _ctx.priceGroups,
                value: _ctx.customer.price_group,
                clearable: true
              }, null, 8, ["placeholder", "price_group", "selectData", "value"])
            ]),
            _createVNode("div", _hoisted_32, [
              _createVNode("button", {
                type: "reset",
                class: "btn btn-white me-3",
                "data-bs-dismiss": "modal",
                onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.goBack()))
              }, _toDisplayString(_ctx.$t('message.CANCEL')), 1),
              _createVNode("button", {
                type: "button",
                class: "btn btn-custom",
                onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.handlerSubmitForm()))
              }, [
                _createVNode("span", _hoisted_33, _toDisplayString(_ctx.$t('message.SUBMIT')), 1)
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["model", "loading"])
  ]))
}