
import { Input, Radio, Select } from '@/components/input-elements';
import { defineComponent, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { Apollo, Notify } from '@/core/services';
import router from '@/router';
import { GET_CUSTOMER_GROUP, GET_ECONOMIC_NUMBER } from '../../customer-groups/graphql/Queries';
import { GET_PRICE_GROUP } from '../../price-groups/graphql/Queries';
import { GET_COUNTRIES } from '@/modules/common/countries/graphql/Queries';
import { AddCustomer } from '../interfaces/index';
import { CREATE_CUSTOMER } from '../graphql/Mutations';
import { CUP_AGREEMENT_PRODUCT } from '@/modules/catalog/products/graphql/Queries';
import Permissions from '@/modules/permission/permissions/components/Permissions.vue';
import { useI18n } from 'vue-i18n';
import InnerLoader from '../../../../components/InnerLoader.vue';
export default defineComponent({
    components: {
        Input,
        Radio,
        Select,
        Permissions,
        InnerLoader
    },
    setup() {
        const cupAgreements = ref([]) as Record<any, any>;
        const customerGroups = ref([]) as Record<any, any>;
        const priceGroups = ref([]) as Record<any, any>;
        const sites = ref([]) as Record<any, any>;
        const phone = ref('');
        const addCustomerForm = ref<null | HTMLFormElement>(null);
        const store = useStore();
        const loader = ref(false);
        const countries = ref([]) as Record<any, any>;
        const showCountryInfo = ref([]) as Record<any, any>;
        const loading = ref(false);
        const economic_number_required = ref(false);
        const economic_number_exist = ref(false);
        const tabs = ref('0');
        const products = ref([]) as Record<any, any>;
        const cup_agreement_products = ref([]) as Record<any, any>;
        const abilities = ref([]) as Record<any, any>;
        const i18n = useI18n();

        cupAgreements.value = [
            {
                label: 'message.YES',
                value: 1
            },
            {
                label: 'message.NO',
                value: 0
            }
        ];

        const customer = ref<AddCustomer>({
            name: '',
            email: '',
            password: '',
            cup_agreement: 0,
            status: false,
            customer_group: null,
            price_group: null,
            site_id: null,
            country_id: null,
            password_confirmation: '',
            phone: '',
            economic_number: null,
            locale: ''
        });

        const checkCustomerGroup = () => {
            const result = customerGroups.value.find(({ value }) => value === customer.value.customer_group);
            if (result.label != 'B2C') {
                return (economic_number_required.value = true);
            }
            return (economic_number_required.value = false);
        };

        const checkEconomicNumber = () => {
            if (customer.value.economic_number != null) {
                loader.value = true;
                Apollo.watchQuery({
                    query: GET_ECONOMIC_NUMBER,
                    fetchPolicy: 'network-only',
                    nextFetchPolicy: 'cache-only',
                    errorPolicy: 'all',
                    variables: {
                        economic_number: customer.value.economic_number,
                        update: false
                    }
                }).subscribe(({ data, errors }) => {
                    if (errors) {
                        // Notify.error(errors[0].message);
                        economic_number_exist.value = true;
                        loader.value = false;
                    } else {
                        economic_number_exist.value = false;
                    }
                    // economic_number_exist.value = false;
                    loader.value = false;
                });
            }
        };

        const getCustomerGroups = () => {
            loader.value = true;
            Apollo.watchQuery({
                query: GET_CUSTOMER_GROUP,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                errorPolicy: 'all'
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                customerGroups.value = [];
                data.customer_groups.data.forEach(element => {
                    customerGroups.value.push({
                        label: element.name,
                        value: element.id
                    });
                });
                loader.value = false;
            });
        };

        getCustomerGroups();

        const getPriceGroup = () => {
            loader.value = true;
            Apollo.watchQuery({
                query: GET_PRICE_GROUP,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                errorPolicy: 'all'
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                priceGroups.value = [];
                data.price_groups.data.forEach(element => {
                    priceGroups.value.push({
                        label: element.name,
                        value: element.id
                    });
                });
                loader.value = false;
            });
        };

        getPriceGroup();

        watchEffect(() => {
            sites.value = [];
            const getSites = store.getters.getAllSites.data.sites;
            getSites.forEach((element, index) => {
                sites.value.push({
                    label: element.name,
                    value: element.id
                });
            });
        });

        const goBack = () => {
            router.push({ name: 'customers_list' });
        };

        // Get Countries
        const getCountries = () => {
            countries.value = [];
            const countriesData = Apollo.readQuery({
                query: GET_COUNTRIES
            });

            const country = [] as Record<any, any>;
            countriesData.countries.forEach(element => {
                country.push({
                    label: element.name,
                    value: element.id,
                    locale: element.locale,
                    currency: element.currency
                });
            });

            countries.value = country;
        };

        getCountries();

        const getProducts = () => {
            loader.value = true;
            Apollo.watchQuery({
                query: CUP_AGREEMENT_PRODUCT,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only'
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }

                products.value = [];
                data.products.data.forEach(element => {
                    products.value.push({
                        value: element.id,
                        desc: JSON.parse(element.description.name).da.name
                    });
                });

                loader.value = false;
            });
        };

        const changeConfig = (id: number) => {
            showCountryInfo.value = [];
            const config = countries.value.find(element => element.value == id);
            showCountryInfo.value.push({
                Currency: config.currency,
                Locale: config.locale
            });
        };

        getProducts();

        const handlerSubmitForm = () => {
            addCustomerForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    const formData = {
                        name: customer.value.name,
                        email: customer.value.email,
                        password: customer.value.password,
                        password_confirmation: customer.value.password_confirmation,
                        country_id: customer.value.country_id,
                        site_id: customer.value.site_id,
                        customer_group_id: customer.value.customer_group,
                        price_group_id: customer.value.price_group,
                        phone_number: customer.value.phone,
                        economic_number: customer.value.economic_number,
                        in_cup_agreement: customer.value.cup_agreement,
                        // status: customer.value.status == true ? 1 : 0,
                        status: 1, //initial status 1 for create by default
                        cup_agreement_products: cup_agreement_products.value,
                        abilities: abilities.value,
                        locale: showCountryInfo.value[0].Locale
                    };

                    if (economic_number_exist.value == true) {
                        Notify.error(i18n.t('message.ECONOMIC_NUMBER_EXISTS'));
                        return;
                    }

                    if (formData.password != formData.password_confirmation) {
                        Notify.error(i18n.t('message.CONFIRM_PASSWORD_DOES_NOT_MATCH'));
                        return;
                    }

                    if (formData.abilities.length == 0) {
                        Notify.error(i18n.t('message.ASSIGN_PERMISSIONS'));
                        return;
                    }

                    loader.value = true;
                    await Apollo.mutate({
                        mutation: CREATE_CUSTOMER,
                        variables: { input: formData },
                        update: (store, { data: { create_customer } }) => {
                            router.push({ name: 'customers_list' });
                            loader.value = false;
                            Notify.success(`${i18n.t('message.RECORD_ADDED_SUCCESSFULLY')}`);
                        }
                    }).catch(() => {
                        loader.value = false;
                        loading.value = false;
                    });
                }
            });
        };

        const onChangeCupAgreement = position => {
            tabs.value = position == 1 ? '2' : '0';
        };

        const getPermissions = data => {
            abilities.value = [];
            abilities.value = data;
        };

        return {
            customer,
            cupAgreements,
            customerGroups,
            priceGroups,
            sites,
            phone,
            addCustomerForm,
            countries,
            showCountryInfo,
            loading,
            tabs,
            cup_agreement_products,
            products,
            loader,
            getPermissions,
            goBack,
            handlerSubmitForm,
            onChangeCupAgreement,
            changeConfig,
            checkCustomerGroup,
            economic_number_required,
            checkEconomicNumber
        };
    }
});
