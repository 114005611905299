
import AddCustomer from "../components/AddCustomer.vue";
import {defineComponent, onMounted} from "vue";
import {setPageHeader, setPageHistory} from "../../../../core/helpers/toolbar";
import {useStore} from "vuex";

export default defineComponent({
    components:{
        AddCustomer
    },
    setup() {
        const store = useStore()
        onMounted(() => {
            setPageHeader({
                title: "message.ADD message.CUSTOMER",
                actionButton : null,
                breadCrumbs: [
                    { name: "message.HOME", link: "/dashboard"},
                    { name: "message.CUSTOMERS", link: "#"},
                    { name: "message.CUSTOMERS", link: "/customer/lists"},
                    { name: "message.ADD message.CUSTOMER"}
                ]
            });
            setPageHistory({});
            store.commit('SET_SEARCH_DATA', {});
        });    
    }
})
